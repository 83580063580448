import { template as template_d0d16f6556ba4fe185caa24b83396130 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d0d16f6556ba4fe185caa24b83396130(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
