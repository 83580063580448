import { template as template_f52aa3a3a8e5478e80efeeed9b374ac9 } from "@ember/template-compiler";
const WelcomeHeader = template_f52aa3a3a8e5478e80efeeed9b374ac9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
