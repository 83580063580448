import { template as template_412594f11f4c4130bd8c7cebac24ece3 } from "@ember/template-compiler";
const FKLabel = template_412594f11f4c4130bd8c7cebac24ece3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
